import { defineStore } from 'pinia';

export const useForgotPasswordStore = defineStore('forgotPasswordStore', () => {
    var displayForgotPasswordForm = ref<boolean>(false);

    const getDisplayForgotPassword = computed(
        () => {
            return displayForgotPasswordForm.value;
        }
    );

    function isOpenForgotPassword() {
        displayForgotPasswordForm.value = true;
    }
    function isClosedForgotPassword() {
        displayForgotPasswordForm.value = false;
    }

    return {
        getDisplayForgotPassword,
        isOpenForgotPassword,
        isClosedForgotPassword
    }
});